import React from 'react';
import { Link } from 'gatsby';
import { MdEmail } from 'react-icons/md';
import { StaticImage } from 'gatsby-plugin-image';
import { FaWhatsapp, FaPhone, FaLinkedin } from 'react-icons/fa'
import './style.css';

const InfoEstatica = ({ Imagem, Colaborador, Cargo, Whatsapp, Telefone, Email, Linkedin, Contato }) => {

    const SeoInterno = {
        altPadrao: 'Vega I.T. - Soluções em Tcnologia para hoteis',
        titulo: 'Vega I.T',
    }

    return (
        <section className='sessao-conteudo'>
            <div className='Info-wrapper'>
                <div className='info-image-wrapper'>
                    {Imagem}
                </div>
                <div className='info-text-wrapper'>
                    <main className='info-escopo-text'>
                        <div className='info-persona-wrapper'>
                            <h2>{Colaborador}</h2>
                            <h4>{Cargo}</h4>
                        </div>
                        <div className='info-escopo-redes-sociais'>
                            <ul>
                                {
                                    Whatsapp &&
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=55${Whatsapp}`} target="blank" alt={`${SeoInterno.altPadrao}`}>
                                            <FaWhatsapp />
                                        </a>
                                    </li>
                                }

                                {
                                    Telefone &&
                                    <li>
                                        <a href={`Tel:${Telefone}`} alt={`${SeoInterno.altPadrao}`}>
                                            <FaPhone />
                                        </a>
                                    </li>
                                }

                                {
                                    Email &&
                                    <li>
                                        <a href={`mailto:${Email}`} target="blank" alt={`${SeoInterno.altPadrao}`}>
                                            <MdEmail />
                                        </a>
                                    </li>
                                }

                                {
                                    Linkedin &&
                                    <li>
                                        <a href={Linkedin} target="blank" alt={`${SeoInterno.altPadrao}`}>
                                            <FaLinkedin />
                                        </a>
                                    </li>
                                }

                            </ul>
                        </div>
                        {
                            Contato &&
                            <div className='info-botao-contato'>
                                <a href={`data:text/vcard,${Contato}`} download={`${Colaborador}.vcf`} target="blank" alt={`${SeoInterno.altPadrao}`}>
                                    Salvar Contato
                                </a>
                            </div>
                        }
                    </main>
                </div>
            </div>
            <div className='info-botao-site-vega'>
                <Link to="/" alt={`${SeoInterno.altPadrao}`}>
                    <StaticImage className='content-image-vega-logo' src="../../images/icones/LogoWhite.svg" loading='eager' alt={`${SeoInterno.altPadrao}`} />
                </Link>
            </div>
        </section>
    )
}

export default InfoEstatica;